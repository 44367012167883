function changeDateFormat(time, format = "YYYY-MM-DD hh:mm:ss") {
  if (!time) return "";
  var datetime = new Date(time);
  var o = {
    "Y+": datetime.getFullYear(),
    "M+": datetime.getMonth() + 1,
    "D+": datetime.getDate(),
    "h+": datetime.getHours(),
    "m+": datetime.getMinutes(),
    "s+": datetime.getSeconds(),
    "q+": Math.floor((datetime.getMonth() + 3) / 3),
    S: datetime.getMilliseconds()
  };
  if (/(Y+)/.test(format))
    format = format.replace(RegExp.$1, (datetime.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  return format;
}

function colorFun(colorStr) {
  if (colorStr == "#67C23A") {
    return "绿色"
  } else if (colorStr == "#F56C6C") {
    return "红色"
  } else if (colorStr == "#4169E1") {
    return "蓝色"
  } else if (colorStr == "#EE00EE") {
    return "紫色"
  } else if (colorStr == "#97FFFF") {
    return "青色"
  } else {
    return "未知颜色"
  }
}

function formatDateToStandard(date) {
  let year = date.getFullYear();
  let month = ('0' + (date.getMonth() + 1)).slice(-2); // 补零
  let day = ('0' + date.getDate()).slice(-2); // 补零
  let hours = ('0' + date.getHours()).slice(-2); // 补零
  let minutes = ('0' + date.getMinutes()).slice(-2); // 补零
  let seconds = ('0' + date.getSeconds()).slice(-2); // 补零

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function checkTime(inputTimeString, type, num) { // 到期了返回true
  if (type == 1) {
    // 解析时间字符串为 Date 对象
    const inputTime = new Date(inputTimeString);
    // 获取当前时间
    const currentTime = new Date();
    // 比较时间
    const isAfterCurrentTime = inputTime < currentTime;
    return isAfterCurrentTime
  } else {
    if (num > 0) {
      return false
    } else {
      return true
    }
  }

}

function registerFormat(time, format = "MM-DD"){
  if (!time) return "";
  var datetime = new Date(time);
  var o = {
    "Y+": datetime.getFullYear(),
    "M+": datetime.getMonth() + 1,
    "D+": datetime.getDate(),
    "h+": datetime.getHours(),
    "m+": datetime.getMinutes(),
    "s+": datetime.getSeconds(),
    "q+": Math.floor((datetime.getMonth() + 3) / 3),
    S: datetime.getMilliseconds()
  };
  if (/(Y+)/.test(format))
    format = format.replace(RegExp.$1, (datetime.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  return format;
}

// 定义颜色数组
const colorsArray = [
  "rgba(255, 159, 64, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 99, 132, 0.2)",
  'rgba(22, 55, 132, 0.2)',
  'rgba(100, 166, 10, 0.2)',
  'rgba(255, 10, 10, 0.2)',
  'rgba(222, 2, 190, 0.2)',
];

function getRandomColorWithOpacityVariants() {
  // 从颜色数组中随机选择一个颜色
  const randomColor = colorsArray[Math.floor(Math.random() * colorsArray.length)];
  // 将选中颜色的透明度从0.2改为1
  const opaqueColor = randomColor.replace('0.2', '1');
  // 返回两个颜色值
  return {
    rgba02: randomColor,
    rgba1: opaqueColor
  };
}

//获取当前时间
function getCurrentDateTime() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从 0 开始的
  const day = String(now.getDate()).padStart(2, '0');

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}
export {
  changeDateFormat,
  colorFun,
  checkTime,
  registerFormat,
  getRandomColorWithOpacityVariants,
  getCurrentDateTime,
  formatDateToStandard,
};
