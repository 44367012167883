<template>
  <div class="row">
    <card
      type="tasks"
      class="col-sm"
      style="height: auto; padding-bottom: 30px"
    >
      <template slot="header">
        <div style="">
          <div class="row">
            <div class="">
              <h4 class="title d-inline">套餐管理</h4>
            </div>
            <div class="ml-auto">
              <div>
                <base-button
                  style="margin-right: 10px"
                  size="sm"
                  type="danger"
                  @click="handleAddPackage"
                  >添加套餐</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <base-table :data="tableData" :columns="columns">
            <template slot="columns">
              <th >id</th>
              <th>套餐名称</th>
              <th>套餐时间</th>
              <th>套餐状态</th>
              <th>添加时间</th>
              <th class="text-right">操作</th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.id }}</td>
              <td>{{ row.desc }}</td>
              <td>{{ row.package_time }}</td>
              <td>
                <base-button
                  v-if="row.state == 0"
                  style="margin-left: 6px"
                  size="sm"
                  round
                  icon
                  type="danger"
                  @click="changePackageState(row.id)"
                  ><i class="tim-icons icon-button-pause"></i
                ></base-button>
                <base-button
                  v-if="row.state == 1"
                  style="margin-left: 6px"
                  size="sm"
                  round
                  icon
                  type="success"
                  @click="changePackageState(row.id)"
                  ><i class="tim-icons icon-triangle-right-17"></i
                ></base-button>

                <!-- <badge type="success" v-if="row.state==1">正常</badge>
                <badge type="danger" v-if="row.state==0">停用</badge> -->
              </td>
              <td>{{ changeDateFormat(row.add_time) }}</td>
              <td class="text-right">
                <base-button
                  type="success"
                  style="margin-left: 6px"
                  size="sm"
                  icon
                  @click="
                    editPackage(
                      row.id,
                      row.name,
                      row.duration,
                      row.price,
                      row.package_type
                    )
                  "
                >
                  <i style="color: #ffffff" class="tim-icons icon-settings"></i>
                </base-button>
              </td>
            </template>
          </base-table>
        </div>
      </template>
      <div>
        <modal
          modelWidth="600px"
          :show.sync="modals"
          headerClasses="justify-content-center"
        >
          <h4 slot="header" class="title title-up">添加套餐</h4>
          <base-input
            label="套餐名称"
            type="text"
            placeholder="请输入套餐名称"
            v-model="packageName"
          ></base-input>
          <!-- <base-input
            label="套餐描述"
            type="text"
            placeholder="请输入套餐描述"
            v-model="packageDesc"
          ></base-input> -->
          <base-input
            label="套餐时间(数字/小时)"
            type="text"
            placeholder="请输入套餐时间"
            v-model="packageDuration"
          ></base-input>
          <!-- <base-input
            label="套餐价格"
            type="text"
            placeholder="请输入套餐价格"
            v-model="packagePrice"
          ></base-input> -->

          <card class="row" style="height: auto">
            <div
              v-for="pkg in selects1.multiple"
              :key="pkg.value"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
              "
            >
              <div style="flex: 1">
                <badge type="default">{{ pkg.label }}</badge>
              </div>
              <el-input-number
                style="flex: 2; width: 10%"
                v-model="packageCounts[pkg.value]"
                :min="1"
              />
            </div>
          </card>
          <div style="margin-top: 18px"></div>
          <template slot="footer">
            <base-button @click="doAddPackage">确定</base-button>
            <base-button type="danger" @click.native="modals = false"
              >取消
            </base-button>
          </template>
        </modal>
      </div>

      <div>
        <modal
          modelWidth="600px"
          :show.sync="modals1"
          headerClasses="justify-content-center"
        >
          <h4 slot="header" class="title title-up">编辑套餐</h4>
          <base-input
            label="套餐名称"
            type="text"
            placeholder="请输入套餐名称"
            v-model="editPackageName"
          ></base-input>
          <!-- <base-input
            label="套餐描述"
            type="text"
            placeholder="请输入套餐描述"
            v-model="editPackageDesc"
          ></base-input> -->

          <base-input
            label="套餐时间(数字/小时)"
            type="text"
            placeholder="请输入套餐时间"
            v-model="editPackageDuration"
          ></base-input>
          <!-- <base-input
            label="套餐价格"
            type="text"
            placeholder="请输入套餐价格"
            v-model="editPackagePrice"
          ></base-input> -->

          <card class="row" style="height: auto">
            <div
              v-for="pkg in selects1.multiple"
              :key="pkg.value"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
              "
            >
              <div style="flex: 1">
                <badge type="default">{{ pkg.label }}</badge>
              </div>
              <el-input-number
                style="flex: 2; width: 10%"
                v-model="packageCounts[pkg.value]"
                :min="1"
              />
            </div>
          </card>
          <div style="margin-top: 18px"></div>
          <template slot="footer">
            <base-button @click="doEditPackage">确定</base-button>
            <base-button type="danger" @click.native="modals1 = false"
              >取消
            </base-button>
          </template>
        </modal>
      </div>
    </card>
  </div>
</template>
<script>
import BaseTable from "@/components/BaseTable";
import Badge from "@/components/Badge";
import { http } from "../../util/http.js";
import Modal from "@/components/Modal";
import { Select, Option } from "element-ui";
import Swal from "sweetalert2";
import { changeDateFormat, checkTime } from "../../util/utils.js";

export default {
  components: {
    BaseTable,
    Badge,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      changeDateFormat,
      packageName: "",
      packageDuration: "",

      editPackageName: "",
      editPackageDuration: "",

      packageId: "",
      columns: ["id", "color", "content", "add_time", "actions"],
      tableData: [],
      packageSIte: [],
      updatePackageList: [],
      modals: false,
      modals1: false,
      selects1: {
        languages: [],
        multiple: "",
      },
      selectedPackages: [],
      packageCounts: {},
    };
  },
  mounted() {
    this.getPackage();
  },
  methods: {
    // 获取所有套餐
    getPackage() {
      http
        .get("/v1/admin/getAllPackageManage", {
          headers: { adminToken: localStorage.getItem("adminToken") },
        })
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: res.data.message,
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }
          this.tableData = res.data.data.allPackage;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 添加套餐
    handleAddPackage() {
      this.modals = true;
    },
    // 执行添加套餐
    doAddPackage() {
      http
        .post(
          "/v1/admin/addPackage",
          {
            packageName: this.packageName,
            packageDuration: this.packageDuration,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "套餐添加失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          Swal.fire({
            icon: "success",
            html: "套餐添加成功",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
          this.modals = false;
          this.getPackage();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 更改套餐状态
    changePackageState(id) {
      http
        .post(
          "/v1/admin/changePackageState",
          {
            packageId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "更改套餐状态失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          Swal.fire({
            icon: "success",
            html: "更改套餐状态成功",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });

          this.getPackage();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
    // 编辑套餐
    editPackage(id) {
      http
        .post(
          "/v1/admin/getUpdatePackage",
          {
            packageId: id,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$notify({
              type: "danger",
              message: "获取更新套餐列表失败",
              timeout: 2000,
              icon: "tim-icons icon-bell-55",
            });
            return;
          }
          // this.editPackagePrice = res.data.data.packageInfo.price;
          this.editPackageName = res.data.data.packageInfo.desc;
          this.editPackageDuration = res.data.data.packageInfo.package_time;
          // this.editPackageDesc = res.data.data.packageInfo.package_desc;

          this.modals1 = true;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });

      this.packageId = id;
    },
    // 执行编辑套餐
    doEditPackage() {
      http
        .post(
          "/v1/admin/updatePackage",
          {
            packageName: this.editPackageName,
            packageDesc: this.editPackageDesc,
            packageDuration: this.editPackageDuration,
            packagePrice: this.editPackagePrice,
            packageId: this.packageId,
          },
          { headers: { adminToken: localStorage.getItem("adminToken") } }
        )
        .then((res) => {
          if (res.data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "更新套餐失败",
              text: res.data.message,
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            return;
          }
          Swal.fire({
            icon: "success",
            title: "更新套餐成功",
            text: res.data.message,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
            },
            buttonsStyling: false,
          });
          this.getPackage();
          this.modals1 = false;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    },
  },
  watch: {
    selectedPackages(newVal, oldVal) {
      oldVal.forEach((pkg) => {
        if (!newVal.includes(pkg)) {
          this.$delete(this.packageCounts, pkg);
        }
      });
      newVal.forEach((pkg) => {
        if (!this.packageCounts[pkg]) {
          this.$set(this.packageCounts, pkg, 1);
        }
      });
    },
  },
};
</script>
<style scoped>
.modal-content {
  width: 800px;
}
</style>



 